import { RequestStatus } from "./types";

export type SurgeryStatus = RequestStatus.CANCELED | RequestStatus.PENDING_ANESTHESIOLOGIST | RequestStatus.ANESTHESIOLOGIST_REJECTED | RequestStatus.PENDING_PURCHASE_SURGERY_PROTHESIS | RequestStatus.COMPLETED | RequestStatus.PENDING_PAYMENT | RequestStatus.ACCEPTED;



export function nextPosibleSurgeryStatus(request:SurgeryStatus):SurgeryStatus[]{
    switch(request){
        case RequestStatus.PENDING_ANESTHESIOLOGIST: //Pending Anestesist approval
            return [RequestStatus.PENDING_PAYMENT, RequestStatus.ANESTHESIOLOGIST_REJECTED, RequestStatus.CANCELED];
        case RequestStatus.PENDING_PAYMENT: 
            return [RequestStatus.CANCELED];        
        case RequestStatus.PENDING_PURCHASE_SURGERY_PROTHESIS: //Pending buy protesis
            return [RequestStatus.ACCEPTED, RequestStatus.CANCELED];
        case RequestStatus.ACCEPTED:
            return [RequestStatus.COMPLETED, RequestStatus.CANCELED];

            
    }
    return [request];
}
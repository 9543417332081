import { useState, useEffect } from 'react';
import axios from '../utils/axios';

interface Appointment {
    id: number;
    requestId: number;
    // Add other appointment fields as needed
}

interface UseSurgeryAppointmentsProps {
    uuidInvestigation: string;
    enabled?: boolean;
}

interface UseSurgeryAppointmentsReturn {
    appointmentsByRequest: Record<number, Appointment>;
    loading: boolean;
    error: Error | null;
}

export const useSurgeryAppointments = ({ 
    uuidInvestigation, 
    enabled = true 
}: UseSurgeryAppointmentsProps): UseSurgeryAppointmentsReturn => {
    const [appointmentsByRequest, setAppointmentsByRequest] = useState<Record<number, Appointment>>({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        async function fetchAppointments() {
            if (!enabled) return;

            setLoading(true);
            setError(null);

            try {
                const appointmentsUrl = `${import.meta.env.VITE_APP_API_URL}/agenda/${uuidInvestigation}/appointments/surgery-requests`;
                const response = await axios.get(
                    appointmentsUrl,
                    { headers: {"Authorization" : localStorage.getItem("jwt")} }
                );
                
                if (response.status === 200) {
                    const appointmentsMap = response.data.appointments.reduce((acc: Record<number, Appointment>, appointment: Appointment) => {
                        acc[appointment.requestAppointment.id] = appointment;
                        return acc;
                    }, {});
                    setAppointmentsByRequest(appointmentsMap);
                }
            } catch (err) {
                setError(err instanceof Error ? err : new Error('Failed to fetch appointments'));
                console.error('Error fetching appointments:', err);
            } finally {
                setLoading(false);
            }
        }

        fetchAppointments();
    }, [uuidInvestigation, enabled]);

    return { appointmentsByRequest, loading, error };
}; 
import React, { useState } from "react";
import Modal from "./general/modal";
import { Typography } from "@mui/material";

const ThumbnailViewer = ({imgSrc}: {imgSrc: string, title: string}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <img
        src={imgSrc}
        alt="thumbnail"
        onClick={() => setIsOpen(true)}
        style={{ width: "100px", cursor: "pointer" }}
      />
      {isOpen && (
        <Modal
            open={isOpen}
            closeModal={() => setIsOpen(false)}>
                <img 
                        src={imgSrc} 
                        alt="thumbnail" 
                        style={{
                            maxWidth: '100%',
                            maxHeight: '90vh',
                            width: 'auto',
                            height: 'auto',
                            display: 'block',
                            margin: '0 auto'
                        }} 
                    />
        </Modal>
        
      )}
    </div>
  );
};

export default ThumbnailViewer;

import { useState, useEffect } from 'react';
import axios from 'axios';
import { ResearcherSubmissionStats } from '../pages/hospital/Analytics/SurveyResearcherStats';

interface UseResearcherStatsReturn {
    loading: boolean;
    error: Error | null;
    surveys: {
        id: number;
        name: string;
    }[];
    researchers: ResearcherSubmissionStats[];
    refetch: () => void;
}

interface UsePatientStatsReturn {
    loading: boolean;
    error: Error | null;
    surveys: {
        id: number;
        name: string;
    }[];
    patients: PatientSubmissionStats[];
    refetch: () => void;
}

export const useResearcherStats = (
    uuidInvestigation: string,
    startDate: number,
    endDate: number
): UseResearcherStatsReturn => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [surveys, setSurveys] = useState<Array<{ id: number; name: string }>>([]);
    const [researchers, setResearchers] = useState<ResearcherSubmissionStats[]>([]);

    const fetchData = async () => {
        try {
            setLoading(true);
            setError(null);

            const response = await axios.get(
                `${import.meta.env.VITE_APP_API_URL}/analytics/${uuidInvestigation}/researchers/survey-submissions/startDate/${startDate}/endDate/${endDate}`,
                {
                    headers: {
                        "Authorization": localStorage.getItem("jwt")
                    }
                }
            );
            const { surveys, researchers } = response.data;

            setSurveys(surveys);
            setResearchers(researchers);
        } catch (err) {
            setError(err instanceof Error ? err : new Error('An error occurred'));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if(startDate && endDate) {
            fetchData();
        }
    }, [uuidInvestigation, startDate, endDate]);

    return {
        loading,
        error,
        surveys,
        researchers,
        refetch: fetchData
    };
};

export interface PatientSubmissionStats {
    patient: {
        id: number;
        patientId: number;
        patientIdInvestigation: number;
    };
    submissions: {
        [key: number]: {
            count: number;
        }
    };
    totalSubmissions: number;
}

export const usePatientStats = (
    uuidInvestigation: string,
    startDate: number,
    endDate: number
): UsePatientStatsReturn => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [surveys, setSurveys] = useState<Array<{ id: number; name: string }>>([]);
    const [patients, setPatients] = useState<PatientSubmissionStats[]>([]);

    const fetchData = async () => {
        try {
            setLoading(true);
            setError(null);

            const response = await axios.get(
                `${import.meta.env.VITE_APP_API_URL}/analytics/${uuidInvestigation}/patients/survey-submissions/startDate/${startDate}/endDate/${endDate}`,
                {
                    headers: {
                        "Authorization": localStorage.getItem("jwt")
                    }
                }
            );
            const { surveys, patients } = response.data;

            setSurveys(surveys);
            setPatients(patients);
        } catch (err) {
            setError(err instanceof Error ? err : new Error('An error occurred'));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if(startDate && endDate) {
            fetchData();
        }
    }, [uuidInvestigation, startDate, endDate]);

    return {
        loading,
        error,
        surveys,
        patients,
        refetch: fetchData
    };
};

import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { Translate } from 'react-localize-redux';
import { EnhancedTable } from '../../../components/general/EnhancedTable';
import Loader from '../../../components/Loader';
import { usePatientStats, useResearcherStats } from '../../../hooks/analytics';
import patient from '../patient';
import { IPatient } from '../../../constants/types';

export interface ResearcherSubmissionStats {
    researcher: {
        id: number;
        name: string;
        surnames: string;
    };
    submissions: {
        [surveyId: number]: {
            surveyName: string;
            count: number;
        };
    };
    totalSubmissions: number;
}
export interface SurveyPatientsStatsPureProps {
    loading: boolean;
    surveys: {
        id: number;
        name: string;
    }[];
    patients: IPatient[];
}

// Pure presentational component
export const SurveyPatientsStatsPure: React.FC<SurveyPatientsStatsPureProps> = ({ 
    loading, 
    surveys, 
    patients 
}) => {
    if (loading) {
        return <Loader />;
    }

    // Create header cells: researcher name + all survey names
    const headCells = [
        { 
            id: "patient", 
            alignment: "left", 
            label: <Translate id={`general.patient`} /> 
        },
        ...surveys.map(survey => ({
            id: `survey_${survey.id}`,
            alignment: "left" as const,
            label: survey.name.toLowerCase().split(' ').map(word => 
                word.charAt(0).toUpperCase() + word.slice(1)
            ).join(' ')
        }))
    ];

    // Transform the data for the table
    const rows = patients.map(patient => {
        const row: any = {
            patient: patient.personalData.name.charAt(0).toUpperCase() + patient.personalData.name.slice(1) + " " + 
            patient.personalData.surnames.charAt(0).toUpperCase() + patient.personalData.surnames.slice(1),
        };
        
        surveys.forEach(survey => {
            const submissionData = patient.submissions[survey.id];
            row[`survey_${survey.id}`] = submissionData ? submissionData.count : 0;
        });

        return row;
    });

    return (
        <EnhancedTable 
            noHeader 
            noSelectable={true} 
            rows={rows} 
            headCells={headCells} 
        />
    );
};

// Container component with hook
interface SurveyPatientsStatsProps {
    startDate: number;
    endDate: number;
    patientsData: any;
    uuidInvestigation: string;
}

const SurveyPatientsStats: React.FC<SurveyPatientsStatsProps> = ({ startDate, endDate, uuidInvestigation, patientsData }) => {
    const { loading, error, surveys, patients } = usePatientStats(uuidInvestigation, startDate, endDate);
    const patientsPersonalData = useMemo(() => {
        if(!patientsData || patientsData.length === 0) {
            return [];
        }
        return patients.map((patient) => {
            const patientPersonalData = patientsData.find((patientData: IPatient) => patientData.id === patient.patient.patientIdInvestigation);
            patient.personalData = patientPersonalData.personalData;
            return patient;
        });
    }, [patients, patientsData]);

    if (error) {
        return <Typography color="error">{error.message}</Typography>;
    }

    return (
        <SurveyPatientsStatsPure 
            key={startDate + endDate}
            loading={loading}
            surveys={surveys}
            patients={patientsPersonalData}
        />
    );
};

export default SurveyPatientsStats;

import React, { useEffect, useMemo, useState } from 'react';
import { AgendasTypes, DepartmentType, IAgenda, IAppointment, IPatient, IResearcher } from '../../../../constants/types';
import { FindPatient } from '../../Billing/find_patient';
import { findPatientByIdAndInvestigation, getAllPatientsInvestigation } from '../../../../db';
import { useSelector } from 'react-redux';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

import { ButtonCancel, ButtonContinue, FieldWrapper } from '../../../../components/general/mini_components';
import { makeAppointmentService } from '../../../../services';
import Loader from '../../../../components/Loader';
import PatientInfo from '../../../../components/PatientInfo';
import { useAgendas } from '../../../../hooks/agenda';
import { IRequest, IServiceInvestigation, ServiceType } from '../../Request/types';
import { useDepartments, useResearchersByDepartmentType, useServiceGeneral, useServiceInvestigation } from '../../../../hooks';

interface NewAppointmentProps extends LocalizeContextProps {
    appointmentInfo: IAppointment;
    typeAgenda: AgendasTypes;
    extraForm? : number,
    cancelCallback: () => void;
    appointmentCreatedCallback: (appointment: IAppointment) => void;
    appointmentErrorCallback: (error: any) => void;
    request?: IRequest;
}

const NewAppointment: React.FC<NewAppointmentProps> = ({ activeLanguage, typeAgenda, appointmentInfo, request,
                                                            extraForm, cancelCallback, appointmentCreatedCallback, appointmentErrorCallback       }) => {
    const [patient, setPatient] = useState<null | IPatient>(null);
    const [patients, setPatients] = useState<IPatient[] | null>(null);
    const [agendaSelected, setAgendaSelected] = useState<IAgenda | null>(null);
    const [service, setService] = useState<null | any>(null);
    const [mainSurgeon, setMainSurgeon] = useState<null | string>(null);
    const [surgicalTeam, setSurgicalTeam] = useState<string[]>([]);
    const [reason, setReason] = useState<string>("");
    const [notes, setNotes] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const servicesAvailable = typeAgenda === AgendasTypes.SURGERY ? useServiceInvestigation(ServiceType.SURGERY ) : { services: [], loadingServices: false };
    const { researchers, loadingDepartments } = typeAgenda === AgendasTypes.SURGERY 
    ? useResearchersByDepartmentType(DepartmentType.SURGERY)
    : { researchers: [], loadingDepartments: false };

    const currentInvestigation = useSelector((state) => state.investigations.currentInvestigation);
    const {agendas} = useAgendas(typeAgenda);

    useEffect(() => {
        async function fetchPatient() {
            if(request){
                const patient = await findPatientByIdAndInvestigation(request.requestsServiceInvestigation[0].patientInvestigation.patientIdInvestigation, currentInvestigation.uuid);
                setPatient(patient);
            }
        }
        fetchPatient();
    }, [request]);

    useEffect(() => {
        const fetchPatient = async () => {
            const uuidInvestigation = currentInvestigation.uuid;
            setPatients(await getAllPatientsInvestigation(uuidInvestigation!));
        };
        fetchPatient();
    }, []);

    useEffect(() => {
        if (appointmentInfo && agendas) {
            const agenda = agendas.find((agenda) => agenda.id === appointmentInfo.agendaId);
            setAgendaSelected(agenda);
        }
    }, []);

    async function onPatientSelected(idPatient: number) {
        const patient = await findPatientByIdAndInvestigation(idPatient, currentInvestigation.uuid);
        setPatient(patient);
    }

    function handleAccept(){
        if(!service && !request){
            setError(true);
            return;
        }
        setLoading(true);
        const serviceOrRequestId = request ? request.id : service.id;
        makeAppointmentService(currentInvestigation.uuid, typeAgenda, agendaSelected?.uuid, patient?.uuid, appointmentInfo.startDateTime, serviceOrRequestId, patient?.personalData.phone, reason, notes)  
            .then((response) => {
                setLoading(false);
                appointmentCreatedCallback(response.appointment);
            })
            .catch((error) => { 
                appointmentErrorCallback(error)
                setLoading(false);
            });
    }

    function renderFields(){
        if(typeAgenda === AgendasTypes.CONSULTATION){
            return <AppointmentConsultationFields agendaSelected={agendaSelected!} setService={setService} 
                extraForm={extraForm} reason={reason} setReason={setReason} notes={notes} setNotes={setNotes} />
        }
        else if(typeAgenda === AgendasTypes.SURGERY){
            return <AppointmentSurgeryFields agendaSelected={agendaSelected!} 
                surgeonsAvailable={researchers} surgicalTeamAvailable={researchers} 
                mainSurgeon={mainSurgeon} setMainSurgeon={setMainSurgeon} 
                surgicalTeam={surgicalTeam} setSurgicalTeam={setSurgicalTeam}
                service={service} servicesAvailable={servicesAvailable.services} setService={setService}
                />
        }
    }

    if(!patients){
        return null;
    }
    else if(loading || loadingDepartments || servicesAvailable?.loadingServices){
        return <Loader />
    }
    else if(!patient){
        return (
            <>
                <FindPatient patients={patients}
                    uuidInvestigation={currentInvestigation.uuid}
                    personalFields={currentInvestigation.personalFields}
                    codeLanguage={activeLanguage.code}
                    onPatientSelected={(idPatient) => onPatientSelected(idPatient)} />
            </>
        );
    }
    else{
        return (
            <Grid container>
                <Grid item xs={12}>
                    <PatientInfo uuidPatient={patient.uuid} activeLanguage={activeLanguage.code}  />
                    <Typography variant="body2"><span style={{ fontWeight: 'bold' }}>Agenda: </span>{ agendaSelected?.name }</Typography>
                    <Typography variant="body2"><span style={{ fontWeight: 'bold' }}>Date: </span>{ appointmentInfo.startDateTime.toLocaleString() }</Typography>
                    {
                        renderFields()
                    }
                    
                </Grid>
                <Grid item xs={12}>
                    <ButtonContinue onClick={handleAccept}>Accept</ButtonContinue> &nbsp;
                    <ButtonCancel onClick={cancelCallback}>Cancel</ButtonCancel>
                </Grid>
            </Grid>

        );
    }
};
const NewAppointmentLocalized = withLocalize(NewAppointment);
export default NewAppointmentLocalized;

interface AppointmentConsultationFieldsProps {
    agendaSelected: IAgenda;
    setService: (service: IServiceInvestigation) => void;
    extraForm?: number;
    reason: string;
    setReason: (reason: string) => void;
    notes: string;
    setNotes: (notes: string) => void;
}

const AppointmentConsultationFields: React.FC<AppointmentConsultationFieldsProps> = ({
    agendaSelected,
    setService,
    extraForm,
    reason,
    setReason,
    notes,
    setNotes
}) => {
    function renderServices(){
        if(agendaSelected!.listServicesInvestigation.length === 0){
            return <Typography variant="body2"><Translate id="pages.hospital.outpatients.form_appointment.no-services" /></Typography>
        }
        const optionsArray = agendaSelected!.listServicesInvestigation.sort((a, b) => a.description.localeCompare(b.description)).map((service) => {
            return (
                <MenuItem value={service.id}>{service.description}</MenuItem>
            )
        });
        
        return (
            <FieldWrapper noWrap ={null}>
                <FormControl fullWidth variant="outlined" margin="dense" >
                    <InputLabel id="service"><Translate id="pages.hospital.outpatients.form_appointment.service" /></InputLabel>
                    <Select
                        labelId="service"
                        id="service"
                        label="Select service"
                        onChange={(event) => {
                            const idService = event.target.value as string;
                            const service = agendaSelected!.listServicesInvestigation.find((service) => service.id === idService);
                            if(service){
                                setService(service);
                            }
                        }}
                    >
                    { optionsArray }
                    </Select>
                </FormControl>
            </FieldWrapper>
        );
    }
    return (
        <>
            {
                renderServices()
            }
            {
                extraForm === 1 && 
                <ExtraAppointmentInfo
                    extraForm={extraForm}
                    reason={reason}
                    setReason={setReason}
                    notes={notes}
                    setNotes={setNotes}
                    />
            }
        </>
        
    )
}


interface ExtraAppointmentInfoProps {
    reason: string;
    setReason: (reason: string) => void;
    notes: string;
    extraForm?: number;
    setNotes: (notes: string) => void;
  }
  
export const ExtraAppointmentInfo: React.FC<ExtraAppointmentInfoProps> = ({ reason, extraForm, setReason, notes, setNotes }) => {
    const optionsArray = ["Autres motifs", "Bronchiolite", "Cervicalgie", "Discopathie", "Dorsalgie", "Dorsolombalgie", "Drainage lymphatique", "Entorse", "Fracture", "Gonalgie", "Gonarthrose", "Hemiparesie / Hemiplegie", "Hernie discale", "Lombalgie", "Lombosciatalgie", "Nevralgie cervico brachiale", "Paralyse facial", "Paralyse cerebral / IMC", "Reeducation perineale", "Reeducation uro-gynecologique", "Ruptures tendons ou tissus mous", "Tendinite", "Traumatiste"];
    
    switch(extraForm){
        case 1:
            return <>
                <Grid item xs={4}>
                    <FieldWrapper noWrap={null}>
                        <FormControl style={{width:'200px'}} variant="outlined" margin="dense">
                        <InputLabel id="reason">Sélectionner le motif</InputLabel>
                        <Select
                            labelId="reason"
                            id="reason"
                            label="Sélectionner le motif"
                            value={reason}
                            onChange={(event) => setReason(event.target.value as string)}
                        >
                            {optionsArray.map((option) => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                        
                        <FormControl style={{width:'200px'}} variant="outlined" margin="dense">
                        <TextField
                            fullWidth
                            id="note"
                            label="Notes"
                            value={notes}
                            onChange={(event) => setNotes(event.target.value)}
                        />
                        </FormControl>
                    </FieldWrapper>
                </Grid>
            </>
        default:
            return null;
    }

  };

interface AppointmentSurgeryFieldsProps {
    agendaSelected: IAgenda;
    surgeonsAvailable: IResearcher[];
    surgicalTeamAvailable: IResearcher[];
    mainSurgeon: string | null;
    surgicalTeam: string[];
    service: string;
    servicesAvailable: IServiceInvestigation[];
    setService: (service: IServiceInvestigation) => void;
    setMainSurgeon: (mainSurgeonUuid: string) => void;
    setSurgicalTeam: (surgicalTeamUuid: string[]) => void;
}
export const AppointmentSurgeryFields = ({agendaSelected, servicesAvailable, 
                                        surgeonsAvailable, surgicalTeamAvailable, mainSurgeon, surgicalTeam, 
                                        service, setService, setMainSurgeon, setSurgicalTeam}: AppointmentSurgeryFieldsProps) => {
    const orderedServices = useMemo(() => servicesAvailable.sort((a, b) => {
        if(a.description === ""){
            return a.service.name.localeCompare(b.service.name)    
        }
        return a.description.localeCompare(b.description)
    }), [servicesAvailable]);
    const orderedSurgeons = surgeonsAvailable.sort((a, b) => a.name.localeCompare(b.name));
    return (
        <Grid item xs={4}>
                    <FieldWrapper noWrap={null}>
                        <FormControl style={{width:'200px'}} variant="outlined" margin="dense">
                            <InputLabel id="reason">Sélectionner le chirurgien principal</InputLabel>
                            <Select
                                labelId="reason"
                                id="reason"
                                label="Sélectionner le chirurgien principal"
                                value={mainSurgeon}
                                onChange={(event) => setMainSurgeon(event.target.value as string)}
                            >
                                {orderedSurgeons.map((surgeon) => (
                                <MenuItem key={surgeon.uuid} value={surgeon.uuid}>{surgeon.name}</MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                        </FieldWrapper>

                        <FieldWrapper>
                            <FormControl style={{width:'200px'}} variant="outlined" margin="dense">
                                <FormLabel component="legend">Équipe chirurgicale</FormLabel>
                                <FormGroup>
                                    {surgicalTeamAvailable.map((member) => (
                                        <FormControlLabel
                                            key={member.uuid}
                                            control={
                                                <Checkbox
                                                    checked={surgicalTeam.includes(member.uuid)}
                                                    onChange={(event) => {
                                                        if (event.target.checked) {
                                                            setSurgicalTeam([...surgicalTeam, member.uuid]);
                                                        } else {
                                                            setSurgicalTeam(surgicalTeam.filter(uuid => uuid !== member.uuid));
                                                        }
                                                    }}
                                                />
                                            }
                                            label={member.name}
                                        />
                                    ))}
                                </FormGroup>
                        </FormControl>
                    </FieldWrapper>
                </Grid>
    )
}

import { Card, Grid, Paper, Snackbar, Typography } from '@mui/material';
import { Alert } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect, useDispatch } from 'react-redux';
import { PERMISSION } from '../../../components/investigation/share/user_roles';
import Loader from '../../../components/Loader';
import { AgendasTypes, IAgendasConfig, IPatient, OutpatientsVisualizationMode } from '../../../constants/types';
import { useDepartments, useRequest, useSnackBarState } from '../../../hooks';
import { checkRequestAppointment, getAgendasConfig } from '../../../services/agenda';
import SectionHeader from '../../components/SectionHeader';
import { FormConsultAppointment } from './FormAppointment';
import EditOutpatients from './EditOutpatients';
import Appointments from './Appointments';
import { useHistory, useParams } from 'react-router-dom';
import { HOSPITAL_OUTPATIENTS_EDIT_ROUTE, HOSPITAL_OUTPATIENTS_ROUTE, HOSPITAL_SURGERY_EDIT_ROUTE, HOSPITAL_SURGERY_SCHEDULE_ROUTE } from '../../../routes/urls';
import { useAgendas, useAppointment } from '../../../hooks/agenda';
import PatientInfo from '../../../components/PatientInfo';
import RequestInfoWithLocalize from '../Request/RequestInfo';

interface AgendasProps extends LocalizeContextProps {
    investigations:any,
    personalData:IPatient,
    type:AgendasTypes
}

const Agendas: React.FC<AgendasProps> = ({ investigations, personalData, type }) => {
    const {action, idRequest, uuidAppointment} = useParams<{action:string, idRequest:string, uuidAppointment:string}>();

    if(!investigations.currentInvestigation){
        return <Loader />
    }

    return <AgendasView investigations={investigations} idRequest={idRequest} uuidAppointment={uuidAppointment}
                personalData={personalData} type={type} action={action} />
}

interface AgendasViewProps extends AgendasProps {
    action:string,
    idRequest:string,
    uuidAppointment:string,
}

const AgendasView: React.FC<AgendasViewProps> = ({ investigations, personalData, type, action, idRequest, uuidAppointment }) => {
    const {request, loadingRequest} = idRequest ? useRequest(parseInt(idRequest)) : {request:null, loadingRequest:false};
    const {appointment, loadingAppointment} = uuidAppointment ? useAppointment(uuidAppointment, investigations.currentInvestigation.uuid) : {appointment:null, loadingAppointment:false};
    const [requestHasAppointment, setRequestHasAppointment] = React.useState(false);
    const [edit, setEdit] = React.useState(action === 'edit');
    const history = useHistory();
    //TODO: This is not the best approach, but it's a quick fix to get the departments in the children components
    const departments = useDepartments();
    const {agendas, loadingAgendas} = useAgendas(type);
    const [showSnackbar, setShowSnackbar] = useSnackBarState();
    
    const [agendasConfig, setAgendasConfig] = React.useState<IAgendasConfig | null>(null);
    const [uuidAgendasSelected, setUuidAgendasSelected] = React.useState<string[]>([]);
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

    const canEditOutPatients = useMemo(() =>{
        if(investigations.currentInvestigation){
            return investigations.currentInvestigation.permissions.includes(PERMISSION.EDIT_OUTPATIENTS);
        }
        else return false;
    }, [investigations.currentInvestigation])

    const canCreateAppointments = useMemo(() =>{
        if(type === AgendasTypes.SURGERY && !idRequest){
            return false;
        }
        if(investigations.currentInvestigation){
            return investigations.currentInvestigation.permissions.includes(PERMISSION.CREATE_APPOINTMENTS);
        }
        else return false;
    }, [investigations.currentInvestigation])


    function toogleEdit(){
        let nextUrl;
        if(edit){
            if(type === AgendasTypes.CONSULTATION){
                nextUrl = HOSPITAL_OUTPATIENTS_ROUTE;
            }
            else{
                nextUrl = HOSPITAL_SURGERY_SCHEDULE_ROUTE;
            }
        }
        else{
            if(type === AgendasTypes.CONSULTATION){
                nextUrl = HOSPITAL_OUTPATIENTS_EDIT_ROUTE.replace(":action", "edit");
            }
            else{
                nextUrl = HOSPITAL_SURGERY_EDIT_ROUTE;
            }
        }
        history.push(nextUrl);
    }

    useEffect(() => {
        if (request) {
            checkRequestAppointment(investigations.currentInvestigation.uuid, request.id)
                .then(response => {
                    if (response.appointment) {
                        setRequestHasAppointment(true);
                    }
                })
                .catch(err => {
                    console.error('Error checking appointment:', err);
                    setShowSnackbar({
                        show: true,
                        message: 'errors.fetch_appointment',
                        severity: 'error'
                    });
                });
        }
    }, [request]);

    useEffect(() => {
        if(investigations.currentInvestigation){
            getAgendasConfig(investigations.currentInvestigation.uuid)
            .then(response => {
                setAgendasConfig(response.agendaConfig);
                
            })
            .catch(err => {
                console.log(err);
            })
        }
       
    }, [investigations.currentInvestigation])
  

    useEffect(() => {
        if(investigations.currentInvestigation && edit){
            if(!canEditOutPatients){
                history.push(HOSPITAL_OUTPATIENTS_ROUTE);
            }
        }
        
    }, [edit, canEditOutPatients])

    function renderAppointments(){
        if(requestHasAppointment){
            return null;
        }
        if(uuidAgendasSelected && selectedDate){
            return(
                <Appointments 
                    uuidAgendas={uuidAgendasSelected} 
                    typeAgenda={type}
                    uuidInvestigation={investigations.currentInvestigation.uuid} 
                    type={agendasConfig?.params[type]?.type ?? 'date'}
                    agendas={agendas ?? []}
                    extraForm={agendasConfig?.params?.consultation?.extraForm}
                    dateSelected={selectedDate} 
                    mode={OutpatientsVisualizationMode.CONSULT} 
                    canCreateAppointments={canCreateAppointments}
                    request={request}
                    appointment={appointment}
                    patientsPersonalData={personalData} 
                />
            )
        }
    }

    function renderCore(){
        if(edit || agendas?.length === 0){
            return(
                <Grid item xs={12}>
                    <EditOutpatients uuidInvestigation={investigations.currentInvestigation.uuid} 
                        billingInfo={investigations.currentInvestigation.billingInfo}
                        agendasConfig={agendasConfig} typeAgenda={type} />
                </Grid>
            )
        }
        return (
            <>
                <Grid container spacing={3}>
                    {
                    request && 
                    <Grid item xs={12}>
                        <Card style={{padding:"1rem"}}>
                            <Typography variant="h4">Scheduling appointment for request {request.id}</Typography><br/>
                            <PatientInfo uuidPatient={request.requestsServiceInvestigation[0].patientInvestigation.uuid}/>
                            <RequestInfoWithLocalize request={request} />
                        </Card>
                    </Grid>
                    }
                    { requestHasAppointment && 
                    <Grid item xs={12}>
                        <Alert 
                            severity="error"
                            variant="filled"
                            
                            sx={{
                                marginBottom: 2,
                                '& .MuiAlert-message': {
                                    fontSize: '1rem'
                                }
                            }}
                        >
                            <Typography variant="h4"><Translate id="pages.hospital.outpatients.agenda.appointment_already_scheduled" /></Typography>
                        </Alert>
                    </Grid>
                    }
                    
                    { !requestHasAppointment && 
                    <Grid item xs={12}>
                        <Card style={{padding:"1rem"}}>
                            <FormConsultAppointment uuidInvestigation={investigations.currentInvestigation.uuid} 
                                    typeAgenda={type}
                                    appointment={appointment}
                                    showAllAgendas={false} dateTimeAppointment={agendasConfig ? agendasConfig.params[type]!.type === "date_time" : false}
                                    agendaChangedCallback={(uuidAgendas) => {
                                        setUuidAgendasSelected(uuidAgendas);
                                    }}
                                    infoAppointmentReadyCallback={(uuidAgendas, date) => {
                                        setUuidAgendasSelected(uuidAgendas);
                                        setSelectedDate(date);
                                    }} 
                                    />
                        </Card>
                    </Grid>
                    }
                </Grid>
                <Grid item xs={12}>
                    {
                        renderAppointments()
                    }     
                </Grid> 
            </>
        );
    }
    if( investigations.loading || !investigations.data || loadingAgendas || !agendasConfig || loadingRequest || loadingAppointment){
        return <Loader />
    }
    return (
        <>
            <Snackbar
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                open={showSnackbar.show}
                autoHideDuration={2000}
                onClose={() => setShowSnackbar({show:false})}>
                    <div>
                        {
                            (showSnackbar.message && showSnackbar.severity) &&
                            <Alert onClose={() => setShowSnackbar({show:false})} severity={showSnackbar.severity}>
                                <Translate id={showSnackbar.message} />
                            </Alert>
                        }
                </div>
            </Snackbar>
            <Grid container spacing={3} >
                <Grid item xs={12}>
                    <SectionHeader section={`outpatients.${type}`} edit={edit} editCallback={canEditOutPatients ? toogleEdit : undefined}  />
                </Grid>
                    {
                        renderCore()
                    }     
                 
            </Grid>
        </>
    );
};

const mapStateToProps = (state:any) => {
	return {
		investigations: state.investigations,
        personalData : state.investigations.currentInvestigation && state.patients.data ? state.patients.data[state.investigations.currentInvestigation.uuid] : []
	}
}

export default withLocalize(connect(mapStateToProps, null)(Agendas));
